import { Submission } from "./submissionSlice";

export async function loadNextPageFetch(iterator: number): Promise<{ iterator?: string, data: Submission[] }> {
	const params = new URLSearchParams();
	if (iterator) {
		params.set('offset', iterator.toString());
	}

	const res = await fetch(`https://api.bikelaneuprising.com/obstructions?${params.toString()}`);
	const data = await res.json();

	return { data };
}
