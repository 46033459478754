import { useMemo, useState } from 'react';
import styles from './Images.module.scss';
import { SubmissionImage } from "./submissionSlice";

type Props = {
  images?: SubmissionImage[];
}

export default function Images({images}: Props) {
  const [hasError, setHasError] = useState(false);
  const [useFallback, setUseFallback] = useState(false);

  const url = useMemo(() => {
    if (!images || images?.length === 0 || hasError) {
      return null;
    }

    return useFallback ? images[0].url : images[0].thumbnails?.card_cover;
  }, [hasError, useFallback, images]);

  if (!url) {
    return <div className={styles.noImage}><span>No image</span></div>;
  }

  return (
    <img className={styles.image} alt="thumbnail" src={url} onError={() => {
      console.error('error loading image:', url);
      if (!useFallback) {
        setUseFallback(true);
      } else {
        setHasError(true);
      }
    }}/>
  )
}
